<template>
  <div>
    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="batchDelete"
        >删除</a-button>

        <a-button
          type="primary"
          @click="deleteAll"
        >全部删除</a-button>

        <a-button
          type="primary"
          @click="allRead"
        >全部标记已读</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="icon" slot-scope="_, record">
        <a-icon
          theme="twoTone"
          :type="iconType(record)"
        />
      </div>
      <div slot="notification_title" slot-scope="title, record">
        <a @click="showInfoModal(record.id)">{{ $lodash.truncate(title, { length: 50, omission: '...'}) }}</a>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <show-notification
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
      @alreadyRead="alreadyRead"
    />
  </div>
</template>

<script>
import {
  deleteAllNotifications,
  deleteNotifications,
  findNotifications,
  updateNotificationsAllRead
} from '@/api/notification'
import Pagination from '@/components/Pagination/index'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AnnouncementList',
  components: {
    Pagination,
    ShowNotification: () => import('@/views/notifications/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false, // 是否显示详情弹窗
      showingId: 0, // 正在查看详情的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          dataIndex: 'icon',
          width: 30,
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '通知类型',
          width: 100,
          dataIndex: 'notification_type'
        },
        {
          title: '通知时间',
          width: 180,
          dataIndex: 'published_at'
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'is_read'
        },
        {
          title: '内容',
          width: 400,
          dataIndex: 'title',
          scopedSlots: { customRender: 'notification_title' }
        }
      ]
    }
  },
  methods: {
    iconType(record) {
      // 后期会有预警通知
      return 'notification'
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    // 已读后更新其他处的通知消息状态
    alreadyRead(id) {
      const objIndex = this.$lodash.findIndex(this.data, { id: id })
      const obj = this.data[objIndex]
      if (!obj || obj.is_read_slug) {
        return
      }

      // 将列表的这一条通知设置为已读
      obj.is_read_slug = true
      obj.is_read = '已读'
      this.$set(this.data, objIndex, obj)

      this.$pullNotifications()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findNotifications(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    batchDelete() {
      const vm = this
      if (vm.selectedRowKeys.length === 0) {
        this.$warning({
          title: '请选择操作对象',
          content: ''
        })
      } else {
        this.$confirm({
          title: '确定删除吗？删除后无法进行恢复，请慎重操作',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            deleteNotifications({ ids: vm.selectedRowKeys }).then((res) => {
              if (res.code === 0) {
                vm.fetchData()
                vm.$pullNotifications()
              }
            })
          }
        })
      }
    },

    // 全部删除
    deleteAll() {
      const vm = this
      this.$confirm({
        title: '确定删除吗？删除后无法进行恢复，请慎重操作',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteAllNotifications().then((res) => {
            if (res.code === 0) {
              vm.fetchData()
              vm.$pullNotifications()
            }
          })
        }
      })
    },

    // 全部标记已读
    allRead() {
      updateNotificationsAllRead().then((res) => {
        if (res.code === 0) {
          this.fetchData()
          this.$pullNotifications()
        }
      })
    }
  }
}
</script>
